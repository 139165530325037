import React from "react";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";

import { Stack, Typography } from "@mui/material";
import {
  Instagram,
  Facebook,
  YouTube,
  Email,
  Phone,
} from "@mui/icons-material";

import { Row } from "./Row";
import { Col } from "./Col";
import { Container } from "./Container";
import { Button } from "./Button";
import { Input } from "./Inputs";

import logo from "../assets/Logo_Footer.png";
import logoSmart from "../assets/logo-smart.svg";

import { useApi } from "../hooks/useApi";
import { API_URL } from "../config/apiUrl";

export const Footer = () => {
  const [{ loading }, fetch] = useApi({ ...API_URL.CONTATO }, { manual: true });
  const navigate = useNavigate();

  const onNavigate = (item) => {
    const { url, outside } = item;

    if (outside) {
      window.open(url);
    }

    if (!outside) {
      window.scrollTo(0, 0);
      navigate(url);
    }
  };

  const onSubmit = (data) => {
    fetch({ data });

    window.location.reload();
  };

  const menus = [
    { nome: "Ache seu pintor", url: "/" },
    // { nome: "Cadastre-se pintor", url: "/cadastro-pintor" },
    // { nome: "Termos de uso Pintor", url: "/termo-pintor" },
    { nome: "Termos de uso Contratante", url: "/termo-contratante" },
    { nome: "Política de Privacidade", url: "/politica-privacidade" },
    {
      nome: "SWPRO Cursos",
      url: "https://digital.sebraesp.com.br/corporativo/swpro",
      outside: true,
    },
    {
      nome: "Clube Pro Pintor",
      url: "https://clubepropintor.com.br/",
      outside: true,
    },
  ];

  return (
    <Container
      maxWidth={false}
      sx={{ backgroundColor: "secondary.main" }}
      loading={loading}
    >
      <Row justifyContent="space-between" alignItems="center" py={8} px={2}>
        <Col xs={12} md={4} justifyContent="center">
          <Stack alignItems={"center"} spacing={4}>
            <Stack
              width="220px"
              height="220px"
              alignItems={"center"}
              justifyContent="center"
            >
              <img src={logo} alt="logo" width="220px" height="220px" />
            </Stack>

            <Stack alignItems="center">
              <Typography color="#fff" fontSize={12} fontWeight={600}>
                Administrado e Desenvolvido por
              </Typography>
              <Stack alignItems="center" direction="row">
                <img
                  src={logoSmart}
                  alt="logo smart"
                  width="100px"
                  height="auto"
                />
                <Typography color="#fff" fontSize={14}>
                  Clube fidelidade{" "}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <Stack
                p={1.5}
                sx={styles.boxIcon}
                onClick={onNavigate.bind(this, {
                  url: "https://www.facebook.com/swpropintor/",
                  outside: true,
                })}
              >
                <Facebook color="secondary" />
              </Stack>
              <Stack
                p={1.5}
                sx={styles.boxIcon}
                onClick={onNavigate.bind(this, {
                  url: "https://www.instagram.com/swpropintor/",
                  outside: true,
                })}
              >
                <Instagram color="secondary" />
              </Stack>
              <Stack
                p={1.5}
                sx={styles.boxIcon}
                onClick={onNavigate.bind(this, {
                  url: "https://www.youtube.com/watch?v=0Vu_Gh7G62Q&list=PL8HjbxoOFszjxkLxFPOAF-IVLh1zd7fbo",
                  outside: true,
                })}
              >
                <YouTube color="secondary" />
              </Stack>
            </Stack>
          </Stack>
        </Col>
        <Col xs={12} md={4} spacing={2} direction="column" mt={2}>
          <Stack xs={12} color="#fff" fontWeight={700}>
            Home
          </Stack>

          {menus.map((item) => (
            <Stack
              xs={12}
              sx={styles.menu}
              mt={1}
              onClick={onNavigate.bind(this, item)}
            >
              <Typography color="#fff">{item.nome}</Typography>
            </Stack>
          ))}
        </Col>
        <Row sm={12} md={4} px={2} mt={2} direction="collumn">
          <Formik
            initialValues={{}}
            onSubmit={(values, { resetForm }) => {
              onSubmit(values);
              resetForm({});
            }}
            enableReinitialize
          >
            {({ handleChange, handleBlur, values }) => (
              <Form>
                <Row>
                  <Col xs={12} color="#fff" fontWeight={700} key="0">
                    Contato
                  </Col>

                  <Col xs={12} color="#fff" fontWeight={700} key="1">
                    <Field
                      as={Input}
                      focused={false}
                      id="nome"
                      name="nome"
                      placeholder="Nome"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nome}
                    />
                  </Col>
                  <Col xs={12} color="#fff" fontWeight={700} key="2">
                    <Field
                      as={Input}
                      focused={false}
                      id="telefone"
                      name="telefone"
                      placeholder="Telefone"
                    />
                  </Col>
                  <Col xs={12} color="#fff" fontWeight={700} key="3">
                    <Field
                      as={Input}
                      focused={false}
                      id="description"
                      name="description"
                      placeholder="Mensagem"
                      rows={4}
                      multiline
                    />
                  </Col>
                  <Col xs={12} color="#fff" fontWeight={700} key="4">
                    <Button fullWidth type="submit">
                      Enviar
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Row>
      </Row>
      <Row
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={1}
        sx={{ backgroundColor: "#003466" }}
      >
        <Col justifyContent="center">
          <Typography color="#fff" fontSize={13}>
            Todos os direitos reservados - 2022 - Smart
          </Typography>
        </Col>{" "}
        <Col justifyContent="center">
          <Typography color="#fff" fontSize={13}>
            <Phone fontSize="12" /> (11) 91187-7987
            {" -  "}
            <Email fontSize="12" /> contato@acheseupintor.com.br
          </Typography>
        </Col>{" "}
        <Col justifyContent="center">
          <Typography
            color="#fff"
            sx={{ cursor: "pointer" }}
            fontSize={13}
            onClick={onNavigate.bind(this, { url: "/politica-privacidade" })}
          >
            Política de Privacidade Ache seu Pintor
          </Typography>
        </Col>
      </Row>
    </Container>
  );
};

const styles = {
  menu: { cursor: "pointer" },
  boxIcon: {
    background: "#fff",
    borderRadius: "100%",
    cursor: "pointer",
  },
};
